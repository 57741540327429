<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="orderinfo" class="control-label">Bestellinfos</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="orderForm.orderinfo" @ready="fillData"></ckeditor>
                    <has-error :form="orderForm" field="orderinfo"></has-error>
                </div>
                <div class="form-group" v-if="$auth.check('producers.edit')">
                    <b-button size="sm" variant="success" @click="editOrderinfo">Speichern</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';



export default {
    name: "OrderInfos",

    props: [
        'producer'
    ],

    data(){
        return {
            orderForm: new window.Form({
                id: "",
                orderinfo: "",
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
        }
    },

     methods:{
        fillData(){
            this.orderForm.fill(this.producer);
        },

        editOrderinfo(){
            this.$Progress.start();
            this.orderForm
                .post("/producers/saveOrderinfo/" + this.producer.id )
                .then(() => {
                    this.$parent.loadProducer();
                    this.$swal({
                        icon: "success",
                        title: "Bestellinformationen wurden editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

}
</script>

<style>

</style>